import { Breadcrumbs, Card, CardActions, CardContent, Grid,   Typography } from "@material-ui/core";
import React from "react";
import AlbumLayout, { useStyles } from "./album_layout";
import Img from 'gatsby-image';
import { MoreButton } from './more_button';
import { useTranslation } from "react-i18next";
import { Link } from "gatsby";

const References = (props:any) => {
    const persona = props.pageContext.persona
    const pagePath = props.pageContext.pagePath;
    const references = props.pageContext.references;
    const { t } = useTranslation();
    const classes = useStyles();

    const image = persona.imageFile[0].childImageSharp.fluid;

    return (
      <React.Fragment>
        <AlbumLayout
          title={`${persona.name}`}
          description={`${persona.description}. ${persona.subdescription}`}
          fluid={image}
          language={persona.language}
          pagePath={pagePath}
          breadCrumbLinks={
            <Breadcrumbs className={classes.breadCrumb} aria-label="breadcrumb">
              <Link
                style={{ textDecoration: "none" }}
                color="inherit"
                to={`/personas/${persona.language}`}
              >
                <Typography color="secondary">{t("home")}</Typography>
              </Link>
              <Link
                style={{ textDecoration: "none" }}
                to={`/personas/${persona.language}/${persona.slug}`}
              >
                <Typography color="secondary">{persona.name}</Typography>
              </Link>
              <Typography color="secondary">{t('references')}</Typography>
            </Breadcrumbs>
          }
        >
          {references.map((reference) => {
            return (
              <Grid item key={reference.id} xs={12} sm={6} md={4}>
                <Link to={`/references/${reference.id}`}>
                  <Card className={classes.card}>
                    {/* https://www.reddit.com/r/gatsbyjs/comments/hlh9rz/gatsby_image_and_material_ui/fx2cjna/ */}
                    <CardContent className={classes.cardContent}>
                      {reference.imageFile && (
                        <Img
                          imgStyle={{ padding: "8px" }}
                          fluid={reference.imageFile[0].childImageSharp.fluid}
                        />
                      )}
                      <Typography gutterBottom variant="h5" component="h2">
                        {reference.name}
                      </Typography>
                      <Typography>
                        {reference.summary} {reference.contributor}{" "}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            );
          })}
        </AlbumLayout>
      </React.Fragment>
    );

}
export default References;